<template>
  <div>
    <CBreadcrumb :items="links" style="margin-top: -28px;"/>

    <CButton
      class="mb-3 text-center"
      color="success"
      variant="outline"
      square
      size="sm"
      @click="generatePdf"
    >
      Generate PDF
    </CButton>

    <CRow>
      <CCol sm="3"></CCol>
      <CCol sm="6">
    <vue-html2pdf
      :show-layout="true"
      :float-layout="false"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      filename="Lintasarta-NDA"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      :html-to-pdf-options="htmlToPdfOptions"
      ref="html2Pdf"
    >
      <section slot="pdf-content" id="content">
        <!-- PDF Content Here -->
        <table width="100%">
          <tr>
            <td width="10%">No. Dok.</td>
            <td>FM-LAS-U2-OMT-008-006</td>
            <td rowspan="4" width="40%" align="center">
              <h4>FORMULIR<br>NON DISCLOSURE<br>AGREEMENT (NDA)</h4>
            </td>
            <td rowspan="4" width="25%" align="center">
              <img src="@/assets/icons/icon.png" style="height: 90px;" />
            </td>
          </tr>
          <tr>
            <td width="10%">Versi</td>
            <td>1.3</td>
          </tr>
          <tr>
            <td width="10%">Hal.</td>
            <td>1 dari 1</td>
          </tr>
          <tr>
            <td width="10%">Label</td>
            <td>Internal</td>
          </tr>
        </table>
      <br>
      Pada hari ini, <strong>{{date(nda.updated_at, 'dddd')}}</strong> Tanggal <strong>{{date(nda.updated_at, 'DD')}}</strong> Bulan <strong>{{date(nda.updated_at, 'MMMM')}}</strong> Tahun <strong>{{date(nda.updated_at, 'YYYY')}}</strong>. Bertempat di Departement/Divisi __________________________________________
      <br><br>Kami yang bertandatangan di bawah ini:
      <br>
      <table width="100%">
        <tr>
          <td width="30%">Nama</td>
          <td><strong>{{nda.name}}</strong></td>
        </tr>
        <tr>
          <td width="30%">Jabatan</td>
          <td><strong>{{nda.role}}</strong></td>
        </tr>
        <tr>
          <td width="30%">Perusahaan/Instansi</td>
          <td><strong>{{nda.company_name}}</strong></td>
        </tr>
        <tr>
          <td width="30%">Jenis Pekerjaan *</td>
          <td></td>
        </tr>
        <tr>
          <td width="30%">Hubungan Pekerjaan Dengan PT. Aplikanusa Lintasarta</td>
          <td> Tenant / Vendor </td>
        </tr>
        <tr>
          <td width="30%">Masa Periode Kerja</td>
          <td><strong>{{date(nda.updated_at, 'DD MMMM YYYY')}}</strong> s/d <strong>{{date(nda.expired_at, 'DD MMMM YYYY')}}</strong></td>
        </tr>
      </table>
      <br>
      
      Dengan ini menyatakan bahwa kami sebagai staf/karyawan dari Perusahaaan/Instansi tersebut di atas, memahami atau mematuhi hal berikut ini :
      <br>
        <br>• Wajib menjaga kerahasiaan data dan informasi milik PT. Aplikanusa Lintasarta serta tidak menyalahgunakan baik untuk kepentingan sendiri, kepentingan perusahaan/instansi ataupun pihak lain.
        <br>• Client atau Pengunjung Datacenter tetap terikat pada kebijakan keamanan informasi yang telah dianut oleh perusahaan/instansi asal pelanggan tersebut.
        <br>• Client atau pengunjung Datacenter wajib memakai tanda pengenal yang telah diberikan PT. Aplikanusa Lintasarta pada waktu kunjungan ke Datacenter.
        <br>• Keamanan perangkat lunak pada perangkat Colocation merupakan tanggung jawab client atau pelanggan Datacenter.
        <br>• PT. Aplikanusa Lintasarta bertanggung jawab atas keamanan fisik baik dari segi lokasi, rak dan kandang (cage), baik ketika pelanggan atau pengunjung 
        Datacenter sedang tidak ada di Datacenter ataupun berada di Datacenter..
        <br>• Ketika client atau pengunjung Datacenter sedang melakukan konfigurasi, keamanan aset pelanggan adalah tanggung jawab client atau pengunjung Datacenter yang bersangkutan.
        <br>• Client atau pengunjung Datacenter tidak diperbolehkan berinteraksi dengan aset yang dimiliki oleh pelanggan lain kecuali telah memiliki dasar dalam perjanjian kerja sama yang sah.
        <br>• Client yang menggunakan rak bersama (sharing) dengan pelanggan lain , dilarang menggunakan aset yang bukan milik pelanggan yang bersangkutan. Hal ini perlu mendapat perlakuan khusus untuk mencegah kabel terputus 
        atau penempatan aset yang tidak seharusnya dari pelanggan tersebut.
        <br>• Jika client menyimpan dan melakukan transmisi cardholder data, maka tanggung jawab untuk mematuhi persyaratan PCI DSS selama bekerja dan melakukan layanan smarthand services terletak pada sisi pelanggan (client).
        <br>• Sesuai dengan PCI DSS dan ISO 27001, PT. Aplikanusa Lintasarta akan memberlakukan proses pengamanan yang ketat terhadap perlindungan informasi dan menjaga kontinuitas bisnis.
        <br>• Telah membaca & memahami Kebijakan Keamanan Informasi milik PT. Aplikanusa Lintasarta.
      
      <br><br>
      Apabila terjadi pelanggaran maka kami bersedia untuk bertanggung jawab sesuai hukum perundang-undangan yang berlaku.
      
      <br><br>
      <CRow>
        <CCol sm="4" />
        <CCol align="center" sm="4">
          <img v-if="nda.status === 'Expired'" src="@/assets/expired.png" style="height: 120px;">
        </CCol>
        <CCol align="center" sm="4">
          <p>Jakarta, {{date(nda.updated_at, 'DD MMMM YYYY')}}</p>
          <img v-if="nda.signature" :src="'https://dcvisit.lintasarta.net/' + nda.signature.replace(/\\\//g, '/').substr(15, nda.signature.length)" style="height: 86px; margin-bottom: 4px;" />
          <img v-if="!nda.signature" src="@/assets/icons/icon.png" style="height: 86px; margin-bottom: 4px;">
          <p><strong>( {{nda.name}} )</strong></p>
        </CCol>
      </CRow>
      
      <small style="color: #999; font-style: italic;">*) Diisi pada visit request form.</small>
      <hr/>
      <p>
        <strong>©HakCipta PT. APLIKANUSA LINTASARTA, Indonesia
        <br>FM-LAS-U2-OMT-008-006 Formulir Non Disclosure Agreement (NDA)</strong>
      </p>

      </section>
    </vue-html2pdf>

      </CCol>
      <CCol sm="3"></CCol>
    </CRow>

  </div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf';
import moment from 'moment';
import { mapState } from 'vuex';

export default {
  components: {
    VueHtml2pdf
  },
  data () {
    return {
      links: [
        {
          text: 'Home',
          href: '#/'
        }, 
        {
          text: 'List NDA Status',
          href: '#/reports/list-nda'
        }, 
        {
          text: 'NDA Preview'
        }
      ],
      htmlToPdfOptions: {
        margin: 0,
        filename: 'Lintasarta-NDA',
        'pdf-quality': '2',
        jsPDF: {
          unit: 'mm',
          format: 'a4',
          orientation: 'portrait'
        },
        html2canvas: {
          dpi: 300,
          letterRendering: true,
          useCORS: true
        }
      },
    }
  },
  computed: {
    ...mapState(['nda']),
  },
  methods: {
    generatePdf () {
      this.$refs.html2Pdf.generatePdf();
    },
    date(value, format) {
      return moment(String(value)).locale('Id').format(format);
    },
  }
}
</script>

<style>
#content {
  background: #fff;
  font-size: 7.5px;
  padding: 12px;
  margin-bottom: 12px;
}

td {
  border: 0.5px solid black;
  border-collapse: collapse;
}

td {
  padding: 4px;
}

table.center {
  margin-left: auto;
  margin-right: auto;
}
</style>
